"use client"

import { CssBaseline } from "@mui/material"
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles"
import { LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { AlertProvider, ConfirmProvider } from "@tc/ui-dfe/hooks"
import { nzLocaleText } from "@tc/ui-dfe/utils/libs/dayjs"
import { SnackbarProvider } from "notistack"
import React, { memo } from "react"
import { AlertSettings } from "./3rd-options"

import theme from "./createTheme"

export interface ThemeProviderProps {
  children?: React.ReactNode
}
export const ThemeProvider = memo((props: ThemeProviderProps) => {
  return (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      adapterLocale="en-nz"
      localeText={nzLocaleText}
    >
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <AlertProvider>
          <SnackbarProvider {...AlertSettings}>
            <ConfirmProvider>{props.children}</ConfirmProvider>
          </SnackbarProvider>
        </AlertProvider>
      </MuiThemeProvider>
    </LocalizationProvider>
  )
})
ThemeProvider.displayName = "ThemeProvider"

"use client"
import { createSvgIcon, useTheme } from "@mui/material"
import { get } from "lodash"
import type { IconProps } from "../types"

const InfoAlert = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
    <path
      d="M12.0084 24C10.3521 24 8.79525 23.6877 7.33794 23.063C5.88063 22.4384 4.60563 21.5797 3.51296 20.487C2.42028 19.3944 1.56162 18.1198 0.936973 16.6633C0.312324 15.2069 0 13.6478 0 11.986C0 10.3242 0.312324 8.76974 0.936973 7.32264C1.56162 5.87553 2.42028 4.60563 3.51296 3.51296C4.60563 2.42028 5.8802 1.56162 7.33665 0.936972C8.79312 0.312324 10.3522 0 12.014 0C13.6758 0 15.2303 0.312324 16.6774 0.936972C18.1245 1.56162 19.3944 2.42028 20.487 3.51296C21.5797 4.60563 22.4384 5.87782 23.063 7.32952C23.6877 8.7812 24 10.3352 24 11.9916C24 13.6479 23.6877 15.2048 23.063 16.6621C22.4384 18.1194 21.5797 19.3944 20.487 20.487C19.3944 21.5797 18.1222 22.4384 16.6705 23.063C15.2188 23.6877 13.6648 24 12.0084 24ZM12 21.46C14.6327 21.46 16.8675 20.5415 18.7045 18.7045C20.5415 16.8675 21.46 14.6327 21.46 12C21.46 9.36735 20.5415 7.13252 18.7045 5.2955C16.8675 3.4585 14.6327 2.54 12 2.54C9.36735 2.54 7.13251 3.4585 5.2955 5.2955C3.4585 7.13252 2.54 9.36735 2.54 12C2.54 14.6327 3.4585 16.8675 5.2955 18.7045C7.13251 20.5415 9.36735 21.46 12 21.46Z"
      fill="white"
    />
    <path
      d="M13.3018 6.88098C13.0424 7.13588 12.7242 7.26333 12.3474 7.26333C11.9706 7.26333 11.6548 7.13359 11.3999 6.8741C11.145 6.61462 11.0176 6.29647 11.0176 5.91967C11.0176 5.54289 11.1473 5.22706 11.4068 4.97219C11.6663 4.71729 11.9844 4.58984 12.3612 4.58984C12.738 4.58984 13.0538 4.71959 13.3087 4.97907C13.5636 5.23855 13.6911 5.5567 13.6911 5.9335C13.6911 6.31028 13.5613 6.62611 13.3018 6.88098Z"
      fill="white"
    />
    <path
      d="M11.3989 15.7451V10.9618C11.3989 10.8535 11.3111 10.7657 11.2028 10.7667C10.9257 10.7686 10.6554 10.7716 10.3841 10.7647C9.66981 10.7452 9.1663 10.2418 9.17996 9.57338C9.19362 8.92451 9.68933 8.44153 10.3851 8.4347C11.3199 8.42494 12.2557 8.42982 13.2325 8.43079C13.5204 8.43079 13.7536 8.66497 13.7536 8.95281V15.7451C13.7536 15.8534 13.8414 15.9402 13.9497 15.9402C14.301 15.9393 13.9302 15.9354 14.2415 15.9422C14.9714 15.9568 15.4681 16.432 15.47 17.1053C15.471 17.7776 14.9724 18.2683 14.2473 18.2732C12.7397 18.283 12.3045 18.282 10.7959 18.2732C10.0738 18.2683 9.56833 17.7727 9.56931 17.1053C9.57028 16.4379 10.0758 15.9578 10.8008 15.9422C11.0594 15.9363 10.9286 15.9393 11.2018 15.9402C11.3101 15.9402 11.398 15.8534 11.398 15.7451H11.3989Z"
      fill="white"
    />
  </svg>,
  "InfoAlert",
)

const InfoAlertIcon = (props: IconProps) => {
  const { fill, sx, ...rest } = props
  const theme = useTheme()
  const _fill = (fill && get(theme.palette, fill)) || theme.palette.text.primary
  return <InfoAlert sx={{ fill: _fill, ...sx }} {...rest} />
}

export default InfoAlertIcon

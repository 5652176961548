import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
} from "@mui/material"
import { CloseIcon } from "@tc/ui-dfe/icons"
import type { ConfirmProps } from "./types"

export const Confirm = (props: ConfirmProps) => {
  const {
    open,
    title,
    message,
    content,
    onConfirm,
    onDismiss,
    dialogProps,
    confirmLabel = "Confirm",
    cancelLabel,
  } = props

  const onClose = (e: never, reason: string) => {
    if (reason !== "backdropClick") onDismiss()
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={"xs"}
      fullWidth
      aria-labelledby={title}
    >
      <Stack
        display="flex"
        direction="row"
        justifyContent="space-between"
        width="inherit"
        alignItems="start"
      >
        {title && (
          <DialogTitle width="100%" variant="h2">
            {title}
          </DialogTitle>
        )}
        <IconButton
          edge="end"
          aria-label="close"
          onClick={onDismiss}
          sx={{
            color: "primary.dark",
            px: 2.5,
            py: 2,
          }}
        >
          <CloseIcon />
        </IconButton>
      </Stack>

      {content ||
        (message && (
          <DialogContent sx={{ pt: 1, px: 2.5 }}>{message}</DialogContent>
        ))}
      <DialogActions sx={{ px: 3, py: 3, gap: 1, mt: 3 }}>
        {cancelLabel && (
          <Button
            onClick={onDismiss}
            data-testid="cancelBtn"
            aria-label="Cancel"
            variant="outlined"
            size="small"
            color="inherit"
            {...dialogProps?.cancelProps}
          >
            {cancelLabel}
          </Button>
        )}
        <Button
          onClick={onConfirm}
          data-testid="confirmBtn"
          aria-label="Confirm"
          variant="contained"
          size="small"
          color="error"
          {...dialogProps?.confirmProps}
        >
          {confirmLabel}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

"use client"
import { createSvgIcon, useTheme } from "@mui/material"
import { get } from "lodash"
import type { IconProps } from "../types"

const Success = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
    <path
      d="M12.0084 24C10.3521 24 8.79525 23.6877 7.33794 23.063C5.88063 22.4384 4.60563 21.5797 3.51296 20.487C2.42028 19.3944 1.56162 18.1198 0.936973 16.6633C0.312324 15.2069 0 13.6478 0 11.986C0 10.3242 0.312324 8.76974 0.936973 7.32264C1.56162 5.87553 2.42028 4.60563 3.51296 3.51296C4.60563 2.42028 5.8802 1.56162 7.33665 0.936972C8.79312 0.312324 10.3522 0 12.014 0C13.6758 0 15.2303 0.312324 16.6774 0.936972C18.1245 1.56162 19.3944 2.42028 20.487 3.51296C21.5797 4.60563 22.4384 5.87782 23.063 7.32952C23.6877 8.7812 24 10.3352 24 11.9916C24 13.6479 23.6877 15.2048 23.063 16.6621C22.4384 18.1194 21.5797 19.3944 20.487 20.487C19.3944 21.5797 18.1222 22.4384 16.6705 23.063C15.2188 23.6877 13.6648 24 12.0084 24ZM12 21.46C14.6327 21.46 16.8675 20.5415 18.7045 18.7045C20.5415 16.8675 21.46 14.6327 21.46 12C21.46 9.36735 20.5415 7.13252 18.7045 5.2955C16.8675 3.4585 14.6327 2.54 12 2.54C9.36735 2.54 7.13251 3.4585 5.2955 5.2955C3.4585 7.13252 2.54 9.36735 2.54 12C2.54 14.6327 3.4585 16.8675 5.2955 18.7045C7.13251 20.5415 9.36735 21.46 12 21.46Z"
      fill="#95C11F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.3041 16.4223L17.982 9.74434C18.4161 9.31022 18.4161 8.60638 17.982 8.17227C17.5479 7.73815 16.844 7.73815 16.4099 8.17227L10.5969 13.9852C10.4017 14.1805 10.0851 14.1805 9.88984 13.9852L7.5461 11.6415C7.11198 11.2074 6.40814 11.2074 5.97402 11.6415C5.53991 12.0756 5.53991 12.7794 5.97402 13.2136L9.18274 16.4223C9.76852 17.0081 10.7183 17.0081 11.3041 16.4223Z"
      fill="#95C11F"
    />
  </svg>,
  "Success",
)

const SuccessIcon = (props: IconProps) => {
  const { fill, sx, ...rest } = props
  const theme = useTheme()
  const _fill = (fill && get(theme.palette, fill)) || theme.palette.text.primary

  return <Success sx={{ fill: _fill, ...sx }} {...rest} />
}

export default SuccessIcon

"use client"

import { ChipProps } from "@mui/material"
import { AlertPortalType } from "@tc/ui-dfe/utils/constants"
import { Fragment, createContext, useContext, useState } from "react"
import type { AlertProps } from "./Alert"
import { Alert, AlertVariantType } from "./Alert"

type EnqueAlert = Omit<AlertProps, "id">
interface AlertReduceState {
  [key: string]: () => React.ReactNode
}
export interface UseAlertProps {
  portalId: string
}
export interface AlertContextProps {
  enque: (props: EnqueAlert) => string
  deque: (id?: string) => void
}
export interface AlertProviderProps {
  children?: React.ReactNode
  config?: {
    autoHideDuration: number
  }
}
export interface AlertOptions {
  action?: string
  actionProps?: ChipProps
}

const AlertContext = createContext<AlertContextProps>({
  enque: () => "",
  deque: () => {},
})

export const AlertProvider = (props: AlertProviderProps) => {
  const [alerts, setAlerts] = useState<AlertReduceState>({})

  const enque = (payload: EnqueAlert): string => {
    const id = `alert-${Math.floor(Math.random() * 100000).toString(16)}`
    setAlerts((state) => {
      return {
        ...state,
        [id]: () => <Alert {...props.config} {...payload} id={id} />,
      }
    })
    return id
  }
  const deque = (id?: string) => {
    setAlerts((state) => {
      let alertId = id
      const newState = { ...state }
      if (!alertId) {
        const ids = Object.keys(newState)
        if (ids.length > 0) {
          alertId = ids[ids.length - 1]
        }
      }
      if (alertId) {
        delete newState[alertId]
      }
      return newState
    })
  }
  return (
    <AlertContext.Provider value={{ enque, deque }}>
      {props.children}
      {Object.entries(alerts).map(([k, alert]) => {
        return <Fragment key={k}>{alert()}</Fragment>
      })}
    </AlertContext.Provider>
  )
}

export const useAlert = (props?: UseAlertProps) => {
  const { portalId } = props || { portalId: AlertPortalType.AppPortal }
  const { enque, deque } = useContext(AlertContext)
  const push = (
    message: string,
    variant: AlertVariantType,
    presist: boolean,
    options?: AlertOptions,
  ): string =>
    enque({
      message,
      variant,
      presist,
      portalId,
      options,
      onClose: deque,
    })

  const error = (
    message: string,
    presist = true,
    options?: AlertOptions,
  ): string => push(message, AlertVariantType.error, presist, options)

  const warning = (
    message: string,
    presist = true,
    options?: AlertOptions,
  ): string => push(message, AlertVariantType.warning, presist, options)

  const info = (
    message: string,
    presist = true,
    options?: AlertOptions,
  ): string => push(message, AlertVariantType.info, presist, options)

  return { error, warning, info, dismiss: deque }
}

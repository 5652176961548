import {
  Link,
  LinkProps,
  Paper,
  Stack,
  Typography,
  useTheme,
} from "@mui/material"
import {
  CloseIcon,
  ErrorAlertIcon,
  InfoAlertIcon,
  SuccessIcon,
  WarningAlertIcon,
} from "@tc/ui-dfe/icons"
import { CustomContentProps, SnackbarContent, useSnackbar } from "notistack"
import { forwardRef, useCallback } from "react"

interface Options {
  action?: string
  actionProps?: LinkProps
}
const Icon = {
  success: SuccessIcon,
  error: ErrorAlertIcon,
  warning: WarningAlertIcon,
  info: InfoAlertIcon,
}
export interface ToastProps extends CustomContentProps {
  children?: React.ReactNode
  title: string
  options?: Options
}
export const Toast = forwardRef<HTMLDivElement, ToastProps>(
  ({ id, ...props }, ref) => {
    const { variant, message, title, options } = props
    const { closeSnackbar } = useSnackbar()
    const handleDismiss = useCallback(() => {
      closeSnackbar(id)
    }, [id, closeSnackbar])
    const IconComponent = Icon[variant]
    const theme = useTheme()
    return (
      <SnackbarContent ref={ref}>
        <Paper
          component={Stack}
          alignItems="flex-start"
          sx={{
            width: { xs: "100%", sm: 390 },
            p: 1.5,
            borderRadius: "3px",
            boxShadow: 4,
            minHeight: 80,
            bgcolor: (theme) => theme.palette.text.primary,
          }}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            gap={1}
            width={"100%"}
          >
            <IconComponent
              sx={{
                fill: (theme) => theme.palette[variant].main,
                width: "24px",
                height: "24px",
              }}
            />
            <Typography
              variant="h4"
              data-testid="toast-title"
              sx={{
                fontWeight: 600,
                flexGrow: 1,
                color: theme.palette.common.white,
              }}
            >
              {title}
            </Typography>

            <CloseIcon
              onClick={handleDismiss}
              stroke={theme.palette.common.white}
              sx={{ height: 24, width: 24, cursor: "pointer" }}
            />
          </Stack>

          <Stack
            flexGrow={1}
            alignItems={"flex-start"}
            sx={{
              ml: 4,
              mr: 8,
            }}
          >
            <Typography
              variant="body1"
              data-testid="toast-message"
              gutterBottom
              color="background.paper"
              sx={{ mb: 0 }}
            >
              {message}
            </Typography>
            {options?.action && (
              <Link
                data-testid="toast-action"
                sx={{ fontWeight: 600 }}
                color="background.paper"
                href={options?.actionProps?.href as string}
                {...options?.actionProps}
              >
                {options?.action}
              </Link>
            )}
          </Stack>
        </Paper>
      </SnackbarContent>
    )
  },
)
Toast.displayName = "Toast"

"use client"
import { createSvgIcon, useTheme } from "@mui/material"
import { get } from "lodash"
import type { IconProps } from "../types"

const Flag = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
  >
    <g>
      <path d="M6 21V5.5H13.1923L13.5923 7.5H19V15.5H13.8077L13.4077 13.5H7V21H6Z" />
    </g>
  </svg>,
  "Flag",
)

const FlagIcon = (props: IconProps) => {
  const { fill, sx, ...rest } = props
  const theme = useTheme()
  const _fill = (fill && get(theme.palette, fill)) || theme.palette.text.primary
  return <Flag sx={{ fill: _fill, ...sx }} {...rest} />
}

export default FlagIcon

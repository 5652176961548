import { SnackbarProviderProps } from "notistack"
import { Toast } from "../Toast"

export const AlertSettings: SnackbarProviderProps = {
  maxSnack: 4,
  autoHideDuration: 4000,
  preventDuplicate: true,
  dense: false,
  hideIconVariant: false,
  anchorOrigin: {
    vertical: "top",
    horizontal: "right",
  },
  Components: {
    error: Toast,
    info: Toast,
    success: Toast,
    warning: Toast,
  },
  classes: {
    containerAnchorOriginTopRight: "notistack-SnackbarContainer-top-right",
  },
}

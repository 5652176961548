"use client"
import { createSvgIcon, useTheme } from "@mui/material"
import { get } from "lodash"
import type { IconProps } from "../types"

const ErrorAlert = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
    <path
      d="M11.9919 18.1244C12.3619 18.1244 12.6747 17.9993 12.9304 17.749C13.1861 17.4987 13.3139 17.1886 13.3139 16.8186C13.3139 16.4486 13.1888 16.1346 12.9385 15.8765C12.6882 15.6184 12.3781 15.4893 12.0081 15.4893C11.6381 15.4893 11.3253 15.6162 11.0696 15.87C10.8139 16.1239 10.6861 16.4358 10.6861 16.8057C10.6861 17.1757 10.8112 17.488 11.0615 17.7425C11.3118 17.9971 11.6219 18.1244 11.9919 18.1244ZM11.9935 13.4835C12.3478 13.4835 12.6491 13.3609 12.8974 13.1156C13.1458 12.8703 13.27 12.5696 13.27 12.2135V7.24071C13.27 6.88467 13.148 6.58399 12.9039 6.33869C12.6598 6.09338 12.3607 5.97073 12.0065 5.97073C11.6522 5.97073 11.3509 6.09338 11.1026 6.33869C10.8542 6.58399 10.73 6.88467 10.73 7.24071V12.2135C10.73 12.5696 10.852 12.8703 11.0961 13.1156C11.3402 13.3609 11.6393 13.4835 11.9935 13.4835ZM12.0084 24C10.3521 24 8.79525 23.6877 7.33794 23.063C5.88063 22.4384 4.60563 21.5797 3.51296 20.487C2.42028 19.3944 1.56162 18.1198 0.936973 16.6634C0.312324 15.2069 0 13.6478 0 11.986C0 10.3242 0.312324 8.76974 0.936973 7.32264C1.56162 5.87553 2.42028 4.60563 3.51296 3.51296C4.60563 2.42028 5.8802 1.56162 7.33665 0.936972C8.79312 0.312324 10.3522 0 12.014 0C13.6758 0 15.2303 0.312324 16.6774 0.936972C18.1245 1.56162 19.3944 2.42028 20.487 3.51296C21.5797 4.60563 22.4384 5.87782 23.063 7.32952C23.6877 8.7812 24 10.3352 24 11.9916C24 13.6479 23.6877 15.2048 23.063 16.6621C22.4384 18.1194 21.5797 19.3944 20.487 20.487C19.3944 21.5797 18.1222 22.4384 16.6705 23.063C15.2188 23.6877 13.6648 24 12.0084 24ZM12 21.46C14.6327 21.46 16.8675 20.5415 18.7045 18.7045C20.5415 16.8675 21.46 14.6327 21.46 12C21.46 9.36735 20.5415 7.13251 18.7045 5.2955C16.8675 3.4585 14.6327 2.54 12 2.54C9.36735 2.54 7.13251 3.4585 5.2955 5.2955C3.4585 7.13251 2.54 9.36735 2.54 12C2.54 14.6327 3.4585 16.8675 5.2955 18.7045C7.13251 20.5415 9.36735 21.46 12 21.46Z"
      fill="#D14D17"
    />
  </svg>,
  "ErrorAlert",
)

const ErrorAlertIcon = (props: IconProps) => {
  const { fill, sx, ...rest } = props
  const theme = useTheme()
  const _fill = (fill && get(theme.palette, fill)) || theme.palette.text.primary
  return <ErrorAlert sx={{ fill: _fill, ...sx }} {...rest} />
}

export default ErrorAlertIcon

import {
  Chip,
  ChipProps,
  IconButton,
  Paper,
  Portal,
  Slide,
  Stack,
  Typography,
} from "@mui/material"
import { CloseIcon, FlagIcon } from "@tc/ui-dfe/icons"
import { startTransition, useEffect, useState } from "react"

export enum AlertVariantType {
  error = "error",
  warning = "warning",
  info = "info",
}
interface Options {
  action?: string
  actionProps?: ChipProps
}

export interface AlertProps {
  children?: React.ReactNode
  id: string
  variant: AlertVariantType
  portalId: string
  options?: Options
  message: string
  onClose?: (id: string) => void
  presist?: boolean
  autoHideDuration?: number
}

export const Alert = (props: AlertProps) => {
  const {
    id,
    variant,
    portalId,
    options,
    message,
    onClose,
    presist,
    autoHideDuration = 5000,
  } = props
  const [open, setOpen] = useState(true)
  const closeHandler = () => {
    setOpen(false)
    startTransition(() => {
      onClose?.(id)
    })
  }
  useEffect(() => {
    if (!presist) setTimeout(() => closeHandler(), autoHideDuration)
  }, [])
  return (
    <Portal container={document.getElementById(portalId)}>
      <Slide direction="down" in={open} mountOnEnter unmountOnExit>
        <Paper
          component={Stack}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          elevation={0}
          sx={{
            px: 1.5,
            py: 2,
            position: "absolute",
            width: "100%",
            borderRadius: 0,
            borderLeft: (theme) => `8px solid ${theme.palette[variant].main}`,
            boxShadow: "3px 4px 5px 0px rgba(0, 0, 0, 0.02)",
          }}
        >
          <Stack direction="row" alignItems="center" flexGrow={1}>
            <FlagIcon sx={{ fill: (theme) => theme.palette[variant].main }} />
            <Typography
              variant="body1"
              data-testid="alert-message"
              color={variant}
            >
              {message}
            </Typography>
          </Stack>
          <Stack direction="row" alignItems="center" gap={2}>
            {options?.action && (
              <Chip
                label={options?.action}
                size="small"
                sx={{
                  bgcolor: (theme) => theme.palette[variant].light,
                  fontSize: "16px",
                  fontWeight: "bold",
                }}
                {...options?.actionProps}
              />
            )}
            <IconButton size="small" onClick={closeHandler}>
              <CloseIcon sx={{ width: "20px", height: "20px" }} />
            </IconButton>
          </Stack>
        </Paper>
      </Slide>
    </Portal>
  )
}

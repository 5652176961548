import { CSSInterpolation, ComponentsPropsList, Theme } from "@mui/material"
type ComponentName = keyof ComponentsPropsList
interface StyleOverridesRootProps {
  ownerState: ComponentsPropsList[ComponentName] & Record<string, unknown>
  theme: Theme & Record<string, unknown>
}

const FOREST = "#5F7C14"
const MPI_BRAND = "#95C11F"
const FRESH_LIME = "#C5D98E"
const PASTEL = "#DEE7B7"
const FOOTER = "#F4F9E9"
const MOJITO = "#EAF1DD"
const CLEAR = "#F8F8F4"

const CHARCOAL = "#1E2C3F"
const SPACE = "#3F4855"
const MUD = "#766F62"
const SMOKE = "#D5D6CF"
const GRAY = "#D6D3CD"
const CLOUD = "#E9E9E9"
const ICE = "#F4F4F4"
const WHITE = "#FFFFFF"

const RED = "#D14D17"
const YELLOW = "#F9B400"
const BLUE = "#194488"

export const MPI_COLOR = Object.freeze({
  brand: MPI_BRAND,
  forest: FOREST,
  freshLime: FRESH_LIME,
  pastel: PASTEL,
  footer: FOOTER,
  mojito: MOJITO,
  clear: CLEAR,
  charcoal: CHARCOAL,
  cloud: CLOUD,
  gray: GRAY,
  mud: MUD,
  ice: ICE,
})

export const Color = {
  white: WHITE,
  black: CHARCOAL,
  primary: {
    main: MPI_BRAND,
    light: FRESH_LIME,
    dark: FOREST,
    text: CHARCOAL,
  },
  secondary: {
    main: SPACE,
    light: SMOKE,
    dark: CHARCOAL,
    text: ICE,
  },
  border: CLOUD,
  background: {
    default: CLEAR,
    paper: WHITE,
  },
  error: {
    main: RED,
    light: "#FDF6F3",
    text: CHARCOAL,
  },
  warning: {
    main: YELLOW,
    light: "#FFF7E5",
    text: CHARCOAL,
  },
  info: {
    main: BLUE,
    light: "#E6F0F7",
    text: ICE,
  },
  success: {
    main: FRESH_LIME,
    dark: MPI_BRAND,
    light: MOJITO,
    text: CHARCOAL,
  },
  text: {
    primary: CHARCOAL,
    secondary: SPACE,
    disabled: SMOKE,
    hint: SMOKE,
  },
}

const colorOptions = {
  palette: {
    primary: {
      main: Color.primary.main,
    },
    secondary: {
      main: Color.secondary.main,
    },
    text: {
      primary: Color.primary.text,
      secondary: Color.secondary.main,
    },
    error: {
      main: Color.error.main,
      light: Color.error.light,
    },
    background: Color.background,
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: ({ ownerState, theme }: StyleOverridesRootProps) => {
          return {
            ...(ownerState.variant === "outlined" && {
              border: `1px solid ${Color.border}`,
            }),
          }
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: Color.text.primary,
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: ({ ownerState, theme }: StyleOverridesRootProps) => {
          return {
            boxShadow: "none",
            textTransform: "none",
            borderRadius: "25px",
            width: "fit-content",
            fontWeight: 400,
            ...(ownerState.size === "small" && {
              fontSize: 14,
              lineHeight: 16 / 14,
              padding: "8px 12px",
              height: "32px",
            }),
            ...(ownerState.variant === "contained" &&
              ownerState.color === "primary" && {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette[ownerState.color].main,
                ":hover": {
                  backgroundColor: theme.palette[ownerState.color].dark,
                },
                "&:disabled": {
                  color: theme.palette.text.primary,
                  backgroundColor: theme.palette[ownerState.color].main,
                  opacity: 0.5,
                },
              }),
            ...(ownerState.variant === "outlined" &&
              ownerState.color === "primary" && {
                color: theme.palette.text.primary,
              }),
            ...(ownerState.variant === "text" &&
              ownerState.color === "primary" && {
                color: theme.palette[ownerState.color].dark,
                fontWeight: 600,
              }),
          } satisfies CSSInterpolation
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: ({ ownerState, theme }: StyleOverridesRootProps) => {
          return {
            backgroundColor: theme.palette.background.paper,
            "&.MuiInputBase-formControl": {
              borderRadius: "2px",
              ...(ownerState.error === true && {
                borderWidth: 2,
                backgroundColor: Color.error.light,
              }),
            },

            "& .MuiInputBase-input": {
              padding: "12px 16px",
              lineHeight: "24px",
            },

            "& .Mui-disabled": {
              backgroundColor: ICE,
              WebkitTextFillColor: `${SPACE} !important`,
            },
            "&.MuiInputBase-multiline": {
              padding: 0,
            },
          }
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: "transparent",
          padding: 0,
          "& .MuiPaper-root": {
            background: Color.black,
            color: WHITE,
            padding: 12,
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: ({ ownerState, theme }: StyleOverridesRootProps) => {
          return {
            padding: "2px 4px",
            fontSize: 14,
            lineHeight: 16 / 14,
            ...(ownerState.variant === "outlined" && {
              color: theme.palette.text.primary,
            }),
          }
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: "math-auto",
          "&.Mui-selected": {
            backgroundColor: MPI_COLOR.mojito,
            color: Color.black,
          },
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: ({ ownerState }: StyleOverridesRootProps) => {
          const root = {
            fontSize: 16,
            lineHeight: 24 / 16,
            letterSpacing: "-0.16px",
          }
          if (ownerState.severity === "error") {
            return {
              ...root,
              backgroundColor: Color.error.light,
              color: Color.error.main,
            }
          }
          if (ownerState.severity === "warning") {
            return {
              ...root,
              backgroundColor: Color.warning.light,
              color: Color.warning.text,
            }
          }
          if (ownerState.severity === "info") {
            return {
              ...root,
              backgroundColor: WHITE,
              borderColor: CLOUD,
              "& .MuiAlert-icon": {
                color: CHARCOAL,
              },
            }
          }
          return {
            ...root,
            backgroundColor: Color.success.light,
            color: Color.success.text,
          }
        },
      },
    },
  },
}

export default colorOptions

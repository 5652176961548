import { createBreakpoints } from "@mui/system"

export const breakpoints = createBreakpoints({})
breakpoints.values.xs = 0
breakpoints.values.sm = 600
breakpoints.values.md = 1024
breakpoints.values.lg = 1512
breakpoints.values.xl = 1728

enum FontWeight {
  LIGHT = 300,
  REGULAR = 400,
  MEDIUM = 600,
  BOLD = 700,
  BLACK = 800,
  STRONG = 900,
}
const fontSize = 16
const baseThemeOptions = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1024,
      lg: 1512,
      xl: 1728,
    },
  },
  typography: {
    fontFamily: "var(--font-source-sans-3)",
    fontSize,
    fontWeightLight: FontWeight.LIGHT,
    fontWeightRegular: FontWeight.REGULAR,
    fontWeightMedium: FontWeight.MEDIUM,
    fontWeightBold: FontWeight.BOLD,
    fontWeightStrong: FontWeight.STRONG,

    // Body Default
    body1: {
      fontSize: 16,
      fontWeight: FontWeight.REGULAR,
      lineHeight: 24 / 16,
      letterSpacing: "-0.16px",
    },
    // Body Small
    body2: {
      fontSize: 14,
      fontWeight: FontWeight.REGULAR,
      letterSpacing: "-0.14px",
      lineHeight: 16 / 14,
    },
    subtitle1: {
      fontSize: 14,
      lineHeight: 16 / 14,
      fontWeight: FontWeight.BOLD,
    },
    subtitle2: {
      fontSize: 14,
      lineHeight: 16 / 14,
      fontWeight: FontWeight.MEDIUM,
    },
    overline: {
      fontSize: 14,
      lineHeight: 16 / 14,
      fontWeight: FontWeight.REGULAR,
    },
    caption: {
      fontSize: 14,
      lineHeight: 16 / 14,
      fontWeight: FontWeight.MEDIUM,
    },
    greeting: {
      fontFamily: "var(--font-din)",
      fontWeight: FontWeight.STRONG,
      fontSize: 42,
      letterSpacing: "-0.42px",
      lineHeight: 36 / 42,
    },
    h1: {
      fontFamily: "var(--font-din)",
      fontWeight: FontWeight.BOLD,
      fontSize: 32,
      letterSpacing: "-0.32px",
      lineHeight: 36 / 32,
    },
    h2: {
      fontFamily: "var(--font-din)",
      fontWeight: FontWeight.MEDIUM,
      fontSize: 24,
      lineHeight: 32 / 24,
    },
    h3: {
      fontFamily: "var(--font-din)",
      fontWeight: FontWeight.BOLD,
      fontSize: 20,
      lineHeight: 28 / 20,
    },
    h4: {
      fontFamily: "var(--font-din)",
      fontWeight: FontWeight.MEDIUM,
      fontSize: 18,
      lineHeight: 28 / 18,
      letterSpacing: "-0.36px",
    },
    // Body Large
    h5: {
      fontSize: 18,
      lineHeight: 28 / 18,
      fontWeight: FontWeight.REGULAR,
      letterSpacing: "-0.18px",
    },
    // Body Medium
    h6: {
      fontSize: 16,
      lineHeight: 24 / 16,
      fontWeight: FontWeight.MEDIUM,
      letterSpacing: "-0.16px",
    },
  },
  shape: {
    borderRadius: 8,
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        "*": {
          boxSizing: "border-box",
        },
        html: {
          MozOsxFontSmoothing: "grayscale",
          WebkitFontSmoothing: "antialiased",
          display: "flex",
          flexDirection: "column",
          minHeight: "100dvh",
          width: "100%",
        },
        body: {
          display: "flex",
          flex: "1 1 auto",
          minHeight: "100dvh",
          width: "100%",
        },
        // notistack toast overriding styles. convention notistack-SnackbarContainer-[vertical]-[horizontal]
        // TODO is there a better way then creating global classes?
        ".notistack-SnackbarContainer-top-right": {
          // 6px is the top padding each toast has, which here only have to remove the 1st one in the toast container
          top: "calc(64px - 6px) !important",
          right: "8px !important",
          maxWidth: "calc(100% - 16px) !important",
          [`@media (min-width: ${breakpoints.values.sm}px)`]: {
            right: "0 !important",
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundImage: "none",
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontFamily: "var(--font-din)",
          fontWeight: FontWeight.BOLD,
          fontSize: 18,
          lineHeight: 28 / 18,
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          border: "3px solid #000",
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          "& .MuiAutocomplete-inputRoot": {
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: 0,
            "& .MuiAutocomplete-input": {
              padding: "12px 16px",
            },
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          "&.MuiTableCell-head": {
            fontWeight: FontWeight.BOLD,
            fontSize: 16,
            lineHeight: 24 / 16,
          },
          "&.MuiTableCell-body": {
            fontWeight: FontWeight.REGULAR,
            fontSize: 16,
            lineHeight: 24 / 16,
            letterSpacing: "-0.16px",
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          fontSize: 14,
          lineHeight: 16 / 16,
          "& .MuiChip-label": {
            "&.MuiChip-labelSmall": {
              fontSize: 14,
              lineHeight: 16 / 14,
              background: "red",
            },
          },
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 34,
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          "& .MuiAccordionSummary-root": {
            padding: "16px",
            "& .MuiAccordionSummary-content": {
              margin: 0,
            },
          },
          "& .MuiAccordionDetails-root": {
            // paddingLeft: "28px",
            // paddingRight: "28px",
          },
        },
      },
    },
    MuiTableSortLabel: {
      styleOverrides: {
        root: {
          "& .MuiTableSortLabel-icon": {
            width: 24,
            height: 24,
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontWeight: FontWeight.REGULAR,
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          width: 24,
          height: 24,
        },
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          marginRight: 0,
        },
      },
    },
    MuiStack: {
      styleOverrides: {
        root: {
          "& ol.MuiStack-root": {
            marginBlockStart: 0,
            marginBlockEnd: 0,
          },
        },
      },
    },
  },
}

export default baseThemeOptions

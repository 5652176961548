"use client"
import { createSvgIcon, useTheme } from "@mui/material"
import { get } from "lodash"
import type { IconProps } from "../types"

const WarningAlert = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
    <path
      d="M1.23573 23C0.988572 23 0.772368 22.942 0.58712 22.826C0.401871 22.71 0.257791 22.5571 0.154877 22.3673C0.0425971 22.1815 -0.00863388 21.9727 0.00118424 21.7412C0.0110024 21.5096 0.0720515 21.2888 0.184331 21.0789L10.946 2.59511C11.0583 2.38421 11.2113 2.23229 11.405 2.13936C11.5987 2.04645 11.7976 2 12.0015 2C12.2054 2 12.4037 2.04645 12.5965 2.13936C12.7892 2.23229 12.9417 2.38421 13.054 2.59511L23.8157 21.0789C23.9279 21.2888 23.989 21.5096 23.9988 21.7412C24.0086 21.9727 23.9574 22.1815 23.8451 22.3673C23.7329 22.5532 23.5864 22.7051 23.4059 22.823C23.2253 22.941 23.0114 23 22.7643 23H1.23573ZM3.1925 20.5925H20.8075L12 5.49627L3.1925 20.5925ZM11.9938 19.2171C12.2972 19.2171 12.5566 19.1082 12.7722 18.8906C12.9878 18.673 13.0956 18.408 13.0956 18.0958C13.0956 17.7835 12.9899 17.5177 12.7784 17.2982C12.567 17.0787 12.3096 16.969 12.0062 16.969C11.7028 16.969 11.4434 17.0766 11.2278 17.2919C11.0122 17.5071 10.9044 17.7708 10.9044 18.083C10.9044 18.3953 11.0101 18.6623 11.2216 18.8842C11.433 19.1061 11.6904 19.2171 11.9938 19.2171ZM11.9938 15.6008C12.2925 15.6008 12.5449 15.4962 12.7511 15.2871C12.9573 15.0779 13.0604 14.8187 13.0604 14.5094V10.9512C13.0604 10.642 12.9593 10.3828 12.7573 10.1736C12.5552 9.96441 12.3049 9.85982 12.0062 9.85982C11.7075 9.85982 11.4551 9.96441 11.2489 10.1736C11.0427 10.3828 10.9396 10.642 10.9396 10.9512V14.5094C10.9396 14.8187 11.0407 15.0779 11.2427 15.2871C11.4448 15.4962 11.6951 15.6008 11.9938 15.6008Z"
      fill="#F7CC4A"
    />
  </svg>,
  "WarningAlert",
)

const WarningAlertIcon = (props: IconProps) => {
  const { fill, sx, ...rest } = props
  const theme = useTheme()
  const _fill = (fill && get(theme.palette, fill)) || theme.palette.text.primary

  return <WarningAlert sx={{ fill: _fill, ...sx }} {...rest} />
}

export default WarningAlertIcon

import { LinkProps } from "@mui/material"
import {
  PaletteColor as MuiPaletteColor,
  Theme,
  createTheme,
  responsiveFontSizes,
} from "@mui/material/styles"
import { deepmerge } from "@mui/utils"
import baseThemeOptions from "./base-options"
import colorOptions, { MPI_COLOR } from "./color-options"

declare module "@mui/material/styles" {
  interface Palette {
    brand: MuiPaletteColor
    forest: MuiPaletteColor
    freshLime: MuiPaletteColor
    pastel: MuiPaletteColor
    footer: MuiPaletteColor
    mojito: MuiPaletteColor
    clear: MuiPaletteColor
    charcoal: MuiPaletteColor
    cloud: MuiPaletteColor
    gray: MuiPaletteColor
    mud: MuiPaletteColor
    ice: MuiPaletteColor
  }

  interface PaletteOptions {
    brand: MuiPaletteColor
    forest: MuiPaletteColor
    freshLime: MuiPaletteColor
    pastel: MuiPaletteColor
    footer: MuiPaletteColor
    mojito: MuiPaletteColor
    clear: MuiPaletteColor
    charcoal: MuiPaletteColor
    cloud: MuiPaletteColor
    gray: MuiPaletteColor
    mud: MuiPaletteColor
    ice: MuiPaletteColor
  }

  interface TypographyVariants {
    greeting: React.CSSProperties
  }

  interface TypographyVariantsOptions {
    greeting?: React.CSSProperties
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    greeting: true
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    brand: true
    forest: true
    freshLime: true
    pastel: true
    footer: true
    mojito: true
    clear: true
    charcoal: true
    cloud: true
    gray: true
    mud: true
    ice: true
  }
}

declare module "notistack" {
  interface VariantOverrides {
    // removes the `warning` variant
    default: false
    success: {
      title: string
      options?: {
        action?: string
        actionProps?: LinkProps
      }
    }
    error: {
      title: string
      options?: {
        action?: string
        actionProps?: LinkProps
      }
    }
    info: {
      title: string
      options?: {
        action?: string
        actionProps?: LinkProps
      }
    }
    warning: {
      title: string
      options?: {
        action?: string
        actionProps?: LinkProps
      }
    }
  }
}

const generateMPIColor = (theme: Theme) =>
  Object.entries(MPI_COLOR).reduce((acc, [key, value]) => {
    return {
      ...acc,
      [key]: theme.palette.augmentColor({
        color: {
          main: value,
          contrastText: theme.palette.getContrastText(value),
        },
        name: key,
      }),
    }
  }, {})

const createCustomTheme = () => {
  const theme = createTheme(deepmerge(baseThemeOptions, colorOptions))
  const themeWithBrandColor = createTheme(theme, {
    palette: { ...generateMPIColor(theme) },
  })
  return responsiveFontSizes(themeWithBrandColor)
}
const dfeTheme = createCustomTheme()
export default dfeTheme

"use client"
import React, { useCallback, useContext, useState } from "react"
import { Confirm } from "./Confirm"
import type { ConfirmMessageProps, ConfirmOptions, DialogProps } from "./types"

export interface ConfirmProviderProps {
  children: React.ReactNode
}

export interface ConfirmContextProps {
  openDialog?: (options: ConfirmOptions) => void
}
const ConfirmContext = React.createContext<ConfirmContextProps>({})

export const ConfirmProvider = ({ children }: ConfirmProviderProps) => {
  const [dialogOpen, setDialogOpen] = useState(false)
  const [dialogConfig, setDialogConfig] = useState<ConfirmOptions>({
    title: "",
    message: "",
    confirmLabel: "",
    cancelLabel: "",
  })

  const openDialog = useCallback(
    ({
      title,
      message,
      confirmLabel,
      cancelLabel,
      content,
      actionCallback,
      dialogProps,
    }: ConfirmOptions) => {
      setDialogOpen(true)
      setDialogConfig({
        title,
        message,
        confirmLabel,
        cancelLabel,
        content,
        actionCallback,
        dialogProps,
      })
    },
    [],
  )

  const resetDialog = useCallback(() => {
    setDialogOpen(false)
    setDialogConfig({
      title: "",
      message: "",
    })
  }, [])

  const onConfirm = useCallback(() => {
    resetDialog()
    dialogConfig?.actionCallback?.(true)
  }, [dialogConfig])

  const onDismiss = useCallback(() => {
    resetDialog()
    dialogConfig?.actionCallback?.(false)
  }, [dialogConfig])

  return (
    <ConfirmContext.Provider value={{ openDialog }}>
      <Confirm
        open={dialogOpen}
        title={dialogConfig.title}
        message={dialogConfig.message}
        content={dialogConfig.content}
        onConfirm={onConfirm}
        onDismiss={onDismiss}
        dialogProps={dialogConfig.dialogProps}
        confirmLabel={dialogConfig.confirmLabel}
        cancelLabel={dialogConfig.cancelLabel}
      />
      {children}
    </ConfirmContext.Provider>
  )
}

export const useConfirm = () => {
  const { openDialog } = useContext(ConfirmContext)
  const confirm = (message: ConfirmMessageProps, dialogProps?: DialogProps) => {
    return new Promise((res) => {
      openDialog?.({ actionCallback: res, dialogProps, ...message })
    })
  }

  return { confirm }
}
